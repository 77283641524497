export function objectId(allIds) {
  if (!allIds.length) return 1
  return allIds.reduce((maxId, nextId) => Math.max(maxId, nextId)) + 1
}

export function nextId(arr) {
  return arr.reduce((maxId, item) => Math.max(item.id, maxId), 0) + 1
}

export function nextIntegerId(arr) {
  function safeParseInt(x) {
    try {
      return parseInt(x)
    } catch (error) {
      return null
    }
  }

  return (
    arr.reduce((maxId, item) => {
      const id = safeParseInt(item.id)
      if (id) {
        return Math.max(id, maxId)
      } else {
        return maxId
      }
    }, 0) + 1
  )
}

export function nextIdAcrossCategories(categories) {
  return (
    1 +
    Object.values(categories).reduce((maxId, category) => {
      return Math.max(
        maxId,
        category.reduce((maxInnerId, character) => {
          return Math.max(maxInnerId, character.id)
        })
      )
    }, 1)
  )
}

// TODO: when you change images to have an allIds key, use the objectId function above
export function imageId(images) {
  return (
    Object.keys(images).reduce(
      (maxId, id) =>
        Math.max(
          // @ts-ignore
          id,
          maxId
        ),
      0
    ) + 1
  )
}
