// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.
import { createSelector } from 'reselect'

import { fullSystemStateSelector } from './fullFileFirstOrder'

export const offlineSelector = createSelector(
  fullSystemStateSelector,
  ({ offline }) => offline ?? []
)
export const offlineFilesSelector = createSelector(offlineSelector, ({ offlineFiles }) => {
  return offlineFiles
})
export const createdOfflineFilesSelector = createSelector(
  offlineSelector,
  ({ createdOfflineFiles }) => {
    return createdOfflineFiles ?? []
  }
)
