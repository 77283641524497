import { omit, keyBy } from 'lodash'

const keysToConsider = (key) => {
  switch (key) {
    case 'notes': {
      return ['tags', 'books', 'characters', 'places']
    }
    case 'characters': {
      return ['tags', 'books']
    }
    case 'places': {
      return ['books', 'tags']
    }
    case 'cards': {
      return ['characters', 'places', 'tags']
    }
    default: {
      return []
    }
  }
}

const entryKeyToEntityKey = (entryKey) => {
  if (entryKey === 'books') {
    return 'bookIds'
  } else {
    return entryKey
  }
}

const removeMissingEntities = (key, entity, missingEntities) => {
  const keys = keysToConsider(key)
  // Custom Attributes
  const withCustomAttributesRemoved = Object.entries(missingEntities.customAttributes).reduce(
    (cleanedEntity, next) => {
      const [entryKey, value] = next
      if (keys.includes(entryKey)) {
        const entityKey = entryKeyToEntityKey(entryKey)
        return {
          ...cleanedEntity,
          [entityKey]: (cleanedEntity[entityKey] ?? []).filter((id) => {
            return !value.includes(id)
          }),
        }
      } else {
        return cleanedEntity
      }
    },
    entity
  )
  // New Attributes
  return Object.entries(missingEntities.attributes).reduce((cleanedEntity, next) => {
    const [_entryKey, value] = next
    if (Array.isArray(value?.values) && typeof value?.id === 'number') {
      return {
        ...cleanedEntity,
        attributes: (cleanedEntity.attributes ?? []).map((attribute) => {
          if (attribute.id === value.id && Array.isArray(attribute.value)) {
            return {
              ...attribute,
              value: attribute.value.filter((id) => {
                return !value.value.includes(id)
              }),
            }
          } else {
            return attribute
          }
        }),
      }
    } else {
      return cleanedEntity
    }
  }, withCustomAttributesRemoved)
}

export const adjustAttributeIds = (entity, conflictedAttributeMapping) => {
  if (Array.isArray(entity?.attributes)) {
    return {
      ...entity,
      attributes: entity.attributes.map((attribute) => {
        const newId = conflictedAttributeMapping[attribute.id]
        if (typeof newId === 'number') {
          return {
            ...attribute,
            id: newId,
          }
        } else {
          return attribute
        }
      }),
    }
  } else {
    return entity
  }
}

export const adjustCategoryIds = (key, entity, conflictedCategoryMapping) => {
  if (key === 'characters') {
    return {
      ...entity,
      attributes: (entity.attributes ?? []).map((attribute) => {
        if (attribute.type === 'base-attribute' && attribute.name === 'categoryId') {
          return {
            ...attribute,
            value: conflictedCategoryMapping[attribute.value] ?? attribute.value,
          }
        } else {
          return attribute
        }
      }),
    }
  } else {
    const categoryId = conflictedCategoryMapping?.[entity.categoryId] ?? entity.categoryId
    if (categoryId !== undefined) {
      return {
        ...entity,
        categoryId,
      }
    } else {
      return entity
    }
  }
}

const adjustImageId = (entity, imageIdMap) => {
  return {
    ...entity,
    ...(entity.imageId ? { imageId: imageIdMap?.[entity.imageId] || entity.imageId } : {}),
  }
}

const adjustCrossReferences = (entity, entityKey, idMapping, attributes) => {
  if (entityKey === 'characters') {
    // Only handle tags for now because that's all characters to deal
    // with have today.
    const tagAttribute = attributes.characters.mergedAttributes.find(({ type, name }) => {
      return type === 'base-attribute' && name === 'tags'
    })
    const tagsAttributeValue =
      tagAttribute &&
      entity.attributes.find(({ id }) => {
        return tagAttribute.id === id
      })
    if (tagAttribute && tagsAttributeValue) {
      return {
        ...entity,
        attributes: entity.attributes.map((attribute) => {
          if (attribute.id === tagAttribute.id) {
            return {
              ...attribute,
              value: attribute.value.map((id) => {
                if (idMapping.tags[id]) {
                  return idMapping.tags[id]
                } else {
                  return null
                }
              }),
            }
          } else {
            return attribute
          }
        }),
      }
    } else {
      return entity
    }
  } else {
    return keysToConsider(entityKey).reduce((accEntity, nextKey) => {
      return {
        ...accEntity,
        ...(entity[nextKey]
          ? {
              [nextKey]: entity[nextKey]
                .map((id) => {
                  if (idMapping[nextKey][id]) {
                    return idMapping[nextKey][id]
                  } else {
                    return null
                  }
                })
                .filter(Boolean),
            }
          : {}),
      }
    }, entity)
  }
}

export const addConflictedEntities = (
  state,
  entityKey,
  key,
  localChanges,
  changedLocalAndOnline,
  missingEntities,
  conflictedAttributeMapping,
  conflictedCategoryMapping,
  mergedImages,
  idMapping,
  attributes
) => {
  const adjustReferences = (entity) => {
    return adjustCrossReferences(
      adjustImageId(
        adjustCategoryIds(
          key,
          adjustAttributeIds(
            removeMissingEntities(key, entity, missingEntities),
            conflictedAttributeMapping
          )
        ),
        mergedImages?.localIdMap
      ),
      entityKey,
      idMapping,
      attributes
    )
  }

  const overwrittenEntities = changedLocalAndOnline[key]
    .filter((entity) => {
      return entity.action === 'OVERWRITE'
    })
    .map(({ data: { offlineEntity } }) => {
      return adjustReferences(offlineEntity)
    })
  const entitiesToRemove = new Set(
    changedLocalAndOnline[key]
      .filter((entity) => {
        return entity.action === 'OVERWRITE'
      })
      .map(({ data: { onlineEntity } }) => {
        return onlineEntity?.id
      })
      .filter(Boolean)
  )

  const entitiesToAdd = localChanges[key]
    .filter((entity) => {
      return entity.action === 'INCLUDE'
    })
    .map(({ data }) => {
      return adjustReferences(data)
    })
    .map((entity) => {
      if (idMapping[entityKey]?.[entity.id]) {
        return {
          ...entity,
          id: idMapping[entityKey]?.[entity.id],
        }
      } else {
        return null
      }
    })
    .filter(Boolean)
    .concat(
      changedLocalAndOnline[key]
        .filter((entity) => {
          return entity.action === 'DUPLICATE'
        })
        .map(({ data }) => {
          return adjustReferences(data.offlineEntity)
        })
    )
    .map((data) => {
      if (idMapping[entityKey]?.[data.id]) {
        return {
          ...data,
          id: idMapping[entityKey]?.[data.id],
        }
      } else {
        return null
      }
    })
    .filter(Boolean)

  return state
    .filter((existingEntity) => {
      return !entitiesToRemove.has(existingEntity.id)
    })
    .concat(entitiesToAdd)
    .concat(overwrittenEntities)
}

export const emptyMissingEntities = {
  customAttributes: {
    tags: [],
    characters: [],
    places: [],
    notes: [],
    books: [],
  },
  attributes: {},
}

export const addConflictedBookEntities = (
  state,
  localChanges,
  changedLocalAndOnline,
  idMapping
) => {
  const booksToArray = (books) => {
    return Object.values(omit(books, 'allIds'))
  }

  const stateAsArray = booksToArray(state)

  const resultBooksAsArray = addConflictedEntities(
    stateAsArray,
    'books',
    'books',
    localChanges,
    changedLocalAndOnline,
    emptyMissingEntities,
    {},
    {},
    {},
    idMapping,
    {}
  )

  return {
    ...keyBy(resultBooksAsArray, 'id'),
    allIds: resultBooksAsArray.map(({ id }) => {
      return id
    }),
  }
}

export const addConflictedObjectEntities = (
  state,
  entityKey,
  key,
  localChanges,
  changedLocalAndOnline,
  idMapping
) => {
  const objectsToArray = (objects) => {
    return Object.entries(objects).map((entry) => {
      const [id, value] = entry
      return {
        ...value,
        id,
      }
    })
  }

  const stateAsArray = objectsToArray(state)

  const resultObjectsAsArray = addConflictedEntities(
    stateAsArray,
    entityKey,
    key,
    localChanges,
    changedLocalAndOnline,
    emptyMissingEntities,
    {},
    {},
    {},
    idMapping,
    {}
  )

  return resultObjectsAsArray.reduce((acc, next) => {
    return {
      ...acc,
      [next.id]: omit(next, 'id'),
    }
  }, {})
}
