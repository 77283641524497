// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.

import { createSelector } from 'reselect'
import { difference } from 'lodash'

import { fileIdFromPlottrProFile, isDeviceFileURL } from '../helpers/file'
import { emptyFile } from '../store/newFileState'
import { SYSTEM_REDUCER_KEYS } from '../reducers/systemReducers'
import { fullSystemStateSelector, fullFileStateSelector } from './fullFileFirstOrder'

export const projectSelector = createSelector(
  fullSystemStateSelector,
  ({ project }) => project ?? {}
)
export const projectNamingModalIsVisibleSelector = createSelector(
  projectSelector,
  ({ projectNamingModalIsVisible }) => projectNamingModalIsVisible
)
export const newProjectTemplateSelector = createSelector(
  projectSelector,
  ({ template }) => template
)
export const filePermissionSelector = createSelector(
  projectSelector,
  ({ permission }) => permission
)
export const fileLoadedSelector = createSelector(projectSelector, ({ fileLoaded }) => fileLoaded)
export const isOfflineSelector = createSelector(projectSelector, ({ isOffline }) => isOffline)
export const isResumingSelector = createSelector(projectSelector, ({ resuming }) => resuming)
export const isCheckingForOfflineDriftSelector = createSelector(
  projectSelector,
  ({ checkingOfflineDrift }) => checkingOfflineDrift
)
export const isOverwritingCloudWithBackupSelector = createSelector(
  projectSelector,
  ({ overwritingCloudWithBackup }) => overwritingCloudWithBackup
)
export const showResumeMessageDialogSelector = createSelector(
  projectSelector,
  ({ showResumeMessageDialog }) => showResumeMessageDialog
)
export const backingUpOfflineFileSelector = createSelector(
  projectSelector,
  ({ backingUpOfflineFile }) => backingUpOfflineFile
)
export const fileURLSelector = createSelector(projectSelector, ({ fileURL }) => fileURL)
export const selectedFileIdSelector = createSelector(fileURLSelector, (fileURL) => {
  if (fileURL && typeof fileURL === 'string') {
    return fileIdFromPlottrProFile(fileURL)
  } else {
    return null
  }
})
export const fileURLLoadedSelector = createSelector(fileURLSelector, (fileURL) => {
  return !!(fileURL && typeof fileURL === 'string' && fileURL.length && fileURL)
})
export const isDeviceFileSelector = createSelector(fileURLSelector, (fileURL) =>
  isDeviceFileURL(fileURL)
)
export const hasAllKeysSelector = createSelector(fullFileStateSelector, (fileState) => {
  const emptyFileState = emptyFile('DummyFile', '2022.11.2')
  return difference(Object.keys(emptyFileState), Object.keys(fileState)).length === 0
})

export const unsavedChangesSelector = createSelector(projectSelector, ({ unsavedChanges }) => {
  return unsavedChanges
})

const conflictResolutionChangesSelector = createSelector(
  projectSelector,
  ({ conflictResolution }) => {
    return conflictResolution ?? {}
  }
)

const DEFAULT_CONFLICT_CHANGES = {
  notes: [],
  characters: [],
  places: [],
  tags: [],
}

export const localOnlyConflictChangesSelector = createSelector(
  conflictResolutionChangesSelector,
  ({ localOnly }) => {
    return localOnly ?? DEFAULT_CONFLICT_CHANGES
  }
)

export const localAndOnlineConflictChangesSelector = createSelector(
  conflictResolutionChangesSelector,
  ({ changedLocalAndOnline }) => {
    return changedLocalAndOnline ?? DEFAULT_CONFLICT_CHANGES
  }
)

export const seriesConflictSelector = createSelector(
  conflictResolutionChangesSelector,
  ({ seriesConflict }) => {
    return seriesConflict ?? null
  }
)

export const onlineFileForConflictResolutionSelector = createSelector(
  conflictResolutionChangesSelector,
  ({ onlineFile }) => {
    return onlineFile ?? null
  }
)

export const conflictResolutionCustomAttributesSelector = createSelector(
  conflictResolutionChangesSelector,
  ({ customAttributes }) => {
    return (
      customAttributes ?? {
        characters: [],
        places: [],
        cards: [],
        scenes: [],
        lines: [],
        notes: [],
      }
    )
  }
)

export const bootedOfflineFileWhilstOnlineSelector = createSelector(
  projectSelector,
  ({ bootedOfflineFileWhilstOnline }) => {
    return bootedOfflineFileWhilstOnline
  }
)
