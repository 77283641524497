import { zipWith } from 'lodash'

const ARRAY_BOOK_BUNDLE_TYPES = ['cards', 'lines']

export const extractLocalOnlyBookConflictDataArrayOfType = (type, conflictData) => {
  if (!ARRAY_BOOK_BUNDLE_TYPES.includes(type)) {
    return {
      ...conflictData,
      books: conflictData.books.map((bookBundle) => {
        return {
          action: bookBundle.action,
          data: {
            ...bookBundle.data[type],
            id: bookBundle.data.book.id,
          },
        }
      }),
    }
  } else {
    return {
      ...conflictData,
      books: conflictData.books.flatMap((bookBundle) => {
        return bookBundle.data[type].map((entity) => {
          return {
            action: bookBundle.action,
            data: entity,
          }
        })
      }),
    }
  }
}

export const extractLocalAndOnlineBookConflictDataArrayOfType = (type, conflictData) => {
  if (!ARRAY_BOOK_BUNDLE_TYPES.includes(type)) {
    return {
      ...conflictData,
      books: conflictData.books.map((bookBundle) => {
        return {
          action: bookBundle.action,
          data: {
            onlineEntity: {
              ...bookBundle.data.onlineEntity[type],
              id: bookBundle.data.onlineEntity.book.id,
            },
            offlineEntity: {
              ...bookBundle.data.offlineEntity[type],
              id: bookBundle.data.offlineEntity.book.id,
            },
          },
        }
      }),
    }
  } else {
    return {
      ...conflictData,
      books: conflictData.books.flatMap((bookBundle) => {
        return zipWith(
          bookBundle.data.onlineEntity[type],
          bookBundle.data.offlineEntity[type],
          (onlineEntity, offlineEntity) => {
            return {
              action: bookBundle.action,
              data: {
                onlineEntity,
                offlineEntity,
              },
            }
          }
        )
      }),
    }
  }
}
