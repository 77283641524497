import {
  SET_FILE_LIST,
  SELECT_FILE,
  SELECT_EMPTY_FILE,
  SHOW_LOADER,
  UNSET_FILE_LOADED,
  SET_OFFLINE,
  SET_RESUMING,
  SET_CHECKING_FOR_OFFLINE_DRIFT,
  SET_OVERWRITING_CLOUD_WITH_BACKUP,
  SET_SHOW_RESUME_MESSAGE_DIALOG,
  SET_BACKING_UP_OFFLINE_FILE,
  START_CREATING_NEW_PROJECT,
  FINISH_CREATING_NEW_PROJECT,
  FILE_LOADED,
  FILE_SAVED,
  SET_KNOWN_FILES,
  SAVE_IMPORT_PLTR_DATA,
  UNDO,
  REDO,
  UNDO_N_TIMES,
  REDO_N_TIMES,
  SET_CONFLICT_RESOLUTION_DATA,
  CLOSE_CONFLICT_RESOLUTION_MODAL,
  SET_CONFLICT_RESOLUTION_ITEM_ACTION,
  BOOTED_OFFLINE_FILE_WHILST_ONLINE,
} from '../constants/ActionTypes'
import { SYSTEM_REDUCER_ACTION_TYPES } from '../reducers/systemReducers'

const DEFAULT_CONFLICT_RESOLUTION_STATE = {
  localOnly: {
    notes: [],
    characters: [],
    places: [],
    tags: [],
    books: [],
  },
  changedLocalAndOnline: {
    notes: [],
    characters: [],
    places: [],
    tags: [],
    books: [],
  },
}

const INITIAL_STATE = {
  permission: null,
  fileURL: null,
  userNameSearchResults: [],
  fileLoaded: false,
  isLoading: false,
  isOffline: false,
  resuming: false,
  checkingOfflineDrift: false,
  overwritingCloudWithBackup: false,
  showResumeMessageDialog: false,
  backingUpOfflineFile: false,
  unsavedChanges: false,
  conflictResolution: DEFAULT_CONFLICT_RESOLUTION_STATE,
  bootedOfflineFileWhilstOnline: false,
}

const NEW_FILE = { fileName: 'New file', id: -1 }

const projectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_FILE: {
      // FIXME: this is set from knownFiles sometimes and from the
      // current file's file record others.  It should only come from
      // known files in the future.
      return {
        ...state,
        permission: action.permission,
        fileLoaded: false,
      }
    }
    case SELECT_EMPTY_FILE:
      return {
        ...state,
        fileURL: null,
        permission: null,
      }
    case UNSET_FILE_LOADED:
      return {
        ...state,
        fileURL: null,
        fileLoaded: false,
        unsavedChanges: false,
      }
    case SHOW_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case SET_OFFLINE: {
      return {
        ...state,
        isOffline: action.isOffline,
      }
    }
    case SET_RESUMING:
      return {
        ...state,
        resuming: action.resuming,
      }
    case SET_CHECKING_FOR_OFFLINE_DRIFT:
      return {
        ...state,
        checkingOfflineDrift: action.checkingOfflineDrift,
      }
    case SET_OVERWRITING_CLOUD_WITH_BACKUP:
      return {
        ...state,
        overwritingCloudWithBackup: action.overwritingCloudWithBackup,
      }
    case SET_SHOW_RESUME_MESSAGE_DIALOG:
      return {
        ...state,
        showResumeMessageDialog: action.showResumeMessageDialog,
      }
    case SET_BACKING_UP_OFFLINE_FILE:
      return {
        ...state,
        backingUpOfflineFile: action.backingUpOfflineFile,
      }
    case START_CREATING_NEW_PROJECT:
      return {
        ...state,
        projectNamingModalIsVisible: true,
        template: action.template,
        defaultName: action.defaultName,
      }
    case FINISH_CREATING_NEW_PROJECT:
      return {
        ...state,
        projectNamingModalIsVisible: false,
        template: null,
        defaultName: null,
      }
    case FILE_LOADED: {
      return {
        ...state,
        fileURL: action.fileURL,
        unsavedChanges: false,
        fileLoaded: true,
      }
    }
    case FILE_SAVED: {
      return {
        ...state,
        unsavedChanges: false,
      }
    }

    case SAVE_IMPORT_PLTR_DATA: {
      return {
        ...state,
        importModal: undefined,
      }
    }

    case SET_CONFLICT_RESOLUTION_DATA: {
      return {
        ...state,
        conflictResolution: {
          onlineFile: action.onlineFile,
          localOnly: action.localOnly,
          changedLocalAndOnline: action.changedLocalAndOnline,
          customAttributes: action.customAttributes,
          seriesConflict: action.seriesDiff,
        },
      }
    }

    case CLOSE_CONFLICT_RESOLUTION_MODAL: {
      return {
        ...state,
        conflictResolution: DEFAULT_CONFLICT_RESOLUTION_STATE,
        resuming: false,
      }
    }

    case SET_CONFLICT_RESOLUTION_ITEM_ACTION: {
      const changeLocation = action.location
      const entityType = action.entityType
      const entityId = action.id
      const newAction = action.action

      if (changeLocation === 'metadata') {
        if (entityType === 'seriesDiff') {
          return {
            ...state,
            conflictResolution: {
              ...state.conflictResolution,
              seriesConflict: {
                ...state.conflictResolution.seriesConflict,
                action: newAction,
              },
            },
          }
        } else {
          return state
        }
      } else {
        return {
          ...state,
          conflictResolution: {
            ...state.conflictResolution,
            [changeLocation]: {
              ...state.conflictResolution[changeLocation],
              [entityType]: state.conflictResolution[changeLocation][entityType].map((entity) => {
                if (
                  typeof entity.data.offlineEntity === 'object' &&
                  typeof entity.data.onlineEntity === 'object'
                ) {
                  if (
                    entity.data.onlineEntity.id === entityId ||
                    (entityType === 'books' && entity.data.onlineEntity.book.id === entityId)
                  ) {
                    return {
                      ...entity,
                      action: newAction,
                    }
                  } else {
                    return entity
                  }
                } else {
                  if (entity.data.id === entityId) {
                    return {
                      ...entity,
                      action: newAction,
                    }
                  } else {
                    return entity
                  }
                }
              }),
            },
          },
        }
      }
    }

    case BOOTED_OFFLINE_FILE_WHILST_ONLINE: {
      return {
        ...state,
        bootedOfflineFileWhilstOnline: true,
      }
    }

    case UNDO_N_TIMES:
    case REDO_N_TIMES:
    case UNDO:
    case REDO:
    default: {
      if (
        action.type === UNDO ||
        action.type === REDO ||
        (!action.type.startsWith('@') && SYSTEM_REDUCER_ACTION_TYPES.indexOf(action.type) === -1)
      ) {
        return {
          ...state,
          unsavedChanges: true,
        }
      }

      return state
    }
  }
}

export default projectReducer
