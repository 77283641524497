import { isEmpty } from 'lodash'
import {
  ATTACH_BOOK_TO_CHARACTER,
  ATTACH_TAG_TO_CHARACTER,
  CREATE_CHARACTER_ATTRIBUTE,
  DELETE_CHARACTER_ATTRIBUTE,
  EDIT_CHARACTER_ATTRIBUTE_METADATA,
  EDIT_CHARACTER_SHORT_DESCRIPTION,
  EDIT_CHARACTER_DESCRIPTION,
  EDIT_CHARACTER_CATEGORY,
  REMOVE_TAG_FROM_CHARACTER,
  LOAD_ATTRIBUTES,
  FILE_LOADED,
  REORDER_CHARACTER_MANUALLY,
  ACTION_CONFLICT_CHANGES,
  UNDO,
  REDO,
  UNDO_N_TIMES,
  REDO_N_TIMES,
} from '../constants/ActionTypes'

const EMPTY_ATTRIBUTE_STATE = []

const INITIAL_STATE = {
  characters: EMPTY_ATTRIBUTE_STATE,
}

const initialStateIfEmpty = (newState, _state) => {
  return !newState || isEmpty(newState) ? INITIAL_STATE : newState
}

const attributesReducer =
  (_dataRepairers) =>
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CREATE_CHARACTER_ATTRIBUTE: {
        const characterAttributeState = state.characters || EMPTY_ATTRIBUTE_STATE

        return {
          ...state,
          characters: [
            ...characterAttributeState,
            { ...action.attribute, id: action.nextAttributeId },
          ],
        }
      }

      case REORDER_CHARACTER_MANUALLY: {
        const categoryExists = state.characters.some((attribute) => {
          return attribute.name == 'category'
        })

        const positionExists = state.characters.some((attribute) => {
          return attribute.name == 'position'
        })
        if (!categoryExists && !positionExists) {
          return {
            ...state,
            characters: [
              ...state.characters,
              {
                name: 'category',
                type: 'base-attribute',
                id: action.categoryAttributeId,
              },
              {
                name: 'position',
                type: 'base-attribute',
                id: action.positionAttributeId,
              },
            ],
          }
        } else if (!categoryExists) {
          return {
            ...state,
            characters: [
              ...state.characters,
              {
                name: 'category',
                type: 'base-attribute',
                id: action.categoryAttributeId,
              },
            ],
          }
        } else if (!positionExists) {
          return {
            ...state,
            characters: [
              ...state.characters,
              {
                name: 'position',
                type: 'base-attribute',
                id: action.positionAttributeId,
              },
            ],
          }
        } else {
          return state
        }
      }

      case EDIT_CHARACTER_ATTRIBUTE_METADATA: {
        const characterAttributeState = state.characters || EMPTY_ATTRIBUTE_STATE

        return {
          ...state,
          characters: [
            ...characterAttributeState.map((attribute) => {
              if (attribute.id === action.id) {
                return {
                  ...attribute,
                  name: action.name,
                  type: action.attributeType,
                }
              }

              return attribute
            }),
          ],
        }
      }

      case DELETE_CHARACTER_ATTRIBUTE: {
        const characterAttributeState = state.characters || EMPTY_ATTRIBUTE_STATE

        return {
          ...state,
          characters: [
            ...characterAttributeState.filter((attribute) => {
              return attribute.id !== action.id
            }),
          ],
        }
      }

      case REMOVE_TAG_FROM_CHARACTER:
      case ATTACH_TAG_TO_CHARACTER: {
        const attributeExists = state.characters.some((attribute) => {
          return attribute.id === action.attributeId
        })
        if (attributeExists) {
          return state
        }

        return {
          ...state,
          characters: [
            ...state.characters,
            {
              name: 'tags',
              type: 'base-attribute',
              id: action.attributeId,
            },
          ],
        }
      }

      case EDIT_CHARACTER_SHORT_DESCRIPTION: {
        const attributeExists = state.characters.some((attribute) => {
          return attribute.id === action.attributeId
        })
        if (attributeExists) {
          return state
        }

        return {
          ...state,
          characters: [
            ...state.characters,
            {
              name: 'shortDescription',
              type: 'base-attribute',
              id: action.attributeId,
            },
          ],
        }
      }

      case EDIT_CHARACTER_DESCRIPTION: {
        const attributeExists = state.characters.some((attribute) => {
          return attribute.id === action.attributeId
        })
        if (attributeExists) {
          return state
        }

        return {
          ...state,
          characters: [
            ...state.characters,
            {
              name: 'description',
              type: 'base-attribute',
              id: action.attributeId,
            },
          ],
        }
      }

      case EDIT_CHARACTER_CATEGORY: {
        const characterAttributes = state.characters || []
        const attributeExists = characterAttributes.some((attribute) => {
          return attribute.id === action.attributeId
        })
        if (attributeExists) {
          return state
        }

        return {
          ...state,
          characters: [
            ...characterAttributes,
            {
              name: 'category',
              type: 'base-attribute',
              id: action.attributeId,
            },
          ],
        }
      }

      case ACTION_CONFLICT_CHANGES: {
        return Object.entries(action.mergedAttributes).reduce((acc, next) => {
          const [key, value] = next
          const { mergedAttributes } = value
          return {
            ...acc,
            [key]: mergedAttributes,
          }
        }, state)
      }

      case UNDO_N_TIMES:
      case REDO_N_TIMES:
      case UNDO:
      case REDO: {
        if (action?.state?.attributes && typeof action.state.attributes === 'object') {
          return action.state.attributes
        } else {
          return state
        }
      }

      case FILE_LOADED: {
        return initialStateIfEmpty(action.data.attributes)
      }

      case LOAD_ATTRIBUTES: {
        return initialStateIfEmpty(action.attributes)
      }

      default: {
        return state
      }
    }
  }

export default attributesReducer
