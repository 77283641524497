import { SET_OFFLINE_FILES, SET_CREATED_OFFLINE_FILES } from '../constants/ActionTypes'

/**
 * e.g. state
 * {
 *   "offlineFiles": [
 *     {
 *       "fileURL": "device:///Users/edwardsteere/Downloads/Untitled-Return-of-the-test.pltr",
 *       "lastOpened": 1636969518301,
 *       "fileName": "Untitled. Return of the Test.",
 *     }
 *   ],
 *   "createdOfflineFiles": [
 *     {
 *       "fileURL": "device:///Users/edwardsteere/Downloads/Untitled-Return-of-the-test.pltr",
 *       "lastOpened": 1636969518301,
 *       "fileName": "Untitled. Return of the Test.",
 *     }
 *   ],
 * }
 */

const INITIAL_STATE = {
  offlineFiles: [],
  createdOfflineFiles: [],
}

const offlineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OFFLINE_FILES: {
      return {
        ...state,
        offlineFiles: action.offlineFiles,
      }
    }
    case SET_CREATED_OFFLINE_FILES: {
      return {
        ...state,
        createdOfflineFiles: action.createdOfflineFiles,
      }
    }
    default: {
      return state
    }
  }
}

export default offlineReducer
