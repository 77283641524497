import { SET_OFFLINE_FILES, SET_CREATED_OFFLINE_FILES } from '../constants/ActionTypes'

export const setOfflineFiles = (offlineFiles) => {
  return {
    type: SET_OFFLINE_FILES,
    offlineFiles,
  }
}

export const setCreatedOfflineFiles = (createdOfflineFiles) => {
  return {
    type: SET_CREATED_OFFLINE_FILES,
    createdOfflineFiles,
  }
}
