// ui actions
export const LOADING_FILE_LIST = 'LOADING_FILE_LIST'
export const LOADED_FILE_LIST = 'LOADED_FILE_LIST'
export const FILE_LOADED = 'FILE_LOADED'
export const FILE_SAVED = 'FILE_SAVED'
export const NEW_FILE = 'NEW_FILE'
export const EDIT_FILENAME = 'EDIT_FILENAME'
export const CHANGE_CURRENT_VIEW = 'CHANGE_CURRENT_VIEW'
export const CHANGE_ORIENTATION = 'CHANGE_ORIENTATION'
export const CHANGE_CURRENT_TIMELINE = 'CHANGE_CURRENT_TIMELINE'
export const SET_CHARACTER_SORT = 'SET_CHARACTER_SORT'
export const SET_PLACE_SORT = 'SET_PLACE_SORT'
export const SET_NOTE_SORT = 'SET_NOTE_SORT'
export const SET_CHARACTER_FILTER = 'SET_CHARACTER_FILTER'
export const SET_PLACE_FILTER = 'SET_PLACE_FILTER'
export const SET_NOTE_FILTER = 'SET_NOTE_FILTER'
export const NAVIGATE_TO_BOOK_TIMELINE = 'NAVIGATE_TO_BOOK_TIMELINE'
export const EXPAND_TIMELINE = 'EXPAND_TIMELINE'
export const COLLAPSE_TIMELINE = 'COLLAPSE_TIMELINE'
export const SET_TIMELINE_FILTER = 'SET_TIMELINE_FILTER'
export const CLEAR_TEMPLATE_FROM_TIMELINE = 'CLEAR_TEMPLATE_FROM_TIMELINE'
export const RESET_TIMELINE = 'RESET_TIMELINE'
export const RECORD_TIMELINE_SCROLL_POSITION = 'RECORD_TIMELINE_SCROLL_POSITION'
export const RECORD_OUTLINE_SCROLL_POSITION = 'RECORD_OUTLINE_SCROLL_POSITION'
export const RECORD_OUTLINE_PLAN_SCROLL_POSITION = 'RECORD_OUTLINE_PLAN_SCROLL_POSITION'
export const OPEN_ATTRIBUTES_DIALOG = 'OPEN_ATTRIBUTES_DIALOG'
export const CLOSE_ATTRIBUTES_DIALOG = 'CLOSE_ATTRIBUTES_DIALOG'
export const SET_TIMELINE_SIZE = 'SET_TIMELINE_SIZE'
export const SET_OUTLINE_FILTER = 'SET_OUTLINE_FILTER'
export const LOAD_FILE = 'LOAD_FILE'
export const SET_ACTIVE_TIMELINE_TAB = 'SET_ACTIVE_TIMELINE_TAB'
export const SET_TIMELINE_VIEW = 'SET_TIMELINE_VIEW'
export const SELECT_CHARACTER_ATTRIBUTE_BOOK_TAB = 'SELECT_CHARACTER_ATTRIBUTE_BOOK_TAB'
export const SELECT_CHARACTER = 'SELECT_CHARACTER'
export const LOAD_UI = 'LOAD_UI'
export const OPEN_NEW_BOOK_DIALOG = 'OPEN_NEW_BOOK_DIALOG'
export const OPEN_EDIT_BOOK_DIALOG = 'OPEN_EDIT_BOOK_DIALOG'
export const CLOSE_BOOK_DIALOG = 'CLOSE_BOOK_DIALOG'
export const CLOSE_SEARCH = 'CLOSE_SEARCH'
export const OPEN_SEARCH = 'OPEN_SEARCH'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SELECT_OUTLINE_CARD = 'SELECT_OUTLINE_CARD'
export const SELECT_NOTE = 'SELECT_NOTE'
export const SELECT_PLACE = 'SELECT_PLACE'
export const SELECT_TAG = 'SELECT_TAG'
export const NEXT_SEARCH_HIT = 'NEXT_SEARCH_HIT'
export const PREVIOUS_SEARCH_HIT = 'PREVIOUS_SEARCH_HIT'
export const CLOSE_IMPORT_PLOTTR_MODAL = 'CLOSE_IMPORT_PLOTTR_MODAL'
export const SHOW_IMPORT_DATA_PICKER = 'SHOW_IMPORT_DATA_PICKER'
export const TOGGLE_ID_MARKED_TO_IMPORT = 'TOGGLE_ID_MARKED_TO_IMPORT'
export const TOGGLE_ALL_SECTION_MARKED_TO_IMPORT = 'TOGGLE_ALL_SECTION_MARKED_TO_IMPORT'
export const TOGGLE_BOOK_TO_IMPORT = 'TOGGLE_BOOK_TO_IMPORT'
export const TOGGLE_CUSTOM_ATTRIBUTE_TO_IMPORT = 'TOGGLE_CUSTOM_ATTRIBUTE_TO_IMPORT'
export const SHOW_PRO_ACCOUNT_RECENT_FILES = 'SHOW_PRO_ACCOUNT_RECENT_FILES'
export const SET_OUTLINE_VIEW = 'SET_OUTLINE_VIEW'
export const SHOW_FULLTEXT_CHAPTER_TITLE = 'SHOW_FULLTEXT_CHAPTER_TITLE'
export const SHOW_FULLTEXT_BEAT_TITLE = 'SHOW_FULLTEXT_BEAT_TITLE'
export const SHOW_FULLTEXT_ONHOVER_CHAPTER_TITLE = 'SHOW_FULLTEXT_ONHOVER_CHAPTER_TITLE'
export const SHOW_FULLTEXT_PAGE_LAYOUT_CONFIG = 'SHOW_FULLTEXT_PAGE_LAYOUT_CONFIG'
export const HIDE_FULLTEXT_PAGE_LAYOUT_CONFIG = 'HIDE_FULLTEXT_PAGE_LAYOUT_CONFIG'
export const SET_FULLTEXT_PLOTLINE_COLOR = 'SET_FULLTEXT_PLOTLINE_COLOR'
export const TOGGLE_FULLTEXT_SHOW_BEAT_GAPS = 'TOGGLE_FULLTEXT_SHOW_BEAT_GAPS'
export const SET_FULLTEXT_CARD_TITLE_ALIGNMENT = 'SET_FULLTEXT_CARD_TITLE_ALIGNMENT'
export const SET_FULLTEXT_BEAT_TITLE_ALIGNMENT = 'SET_FULLTEXT_BEAT_TITLE_ALIGNMENT'
export const ADD_OUTLINE_FULLTEXT_ZOOM = 'ADD_OUTLINE_FULLTEXT_ZOOM'
export const REDUCE_OUTLINE_FULLTEXT_ZOOM = 'REDUCE_OUTLINE_FULLTEXT_ZOOM'
export const SHOW_FULLTEXT_CARD_HOVER_DETAILS = 'SHOW_FULLTEXT_CARD_HOVER_DETAILS'
export const HIDE_FULLTEXT_CARD_HOVER_DETAILS = 'HIDE_FULLTEXT_CARD_HOVER_DETAILS'
export const TOGGLE_OUTLINE_CARD_COLOR_SOURCE = 'TOGGLE_OUTLINE_CARD_COLOR_SOURCE'
// card dialog
export const SET_CARD_DIALOG_OPEN = 'SET_CARD_DIALOG_OPEN'
export const SET_CARD_DIALOG_CLOSE = 'SET_CARD_DIALOG_CLOSE'
export const START_DELETING_CARD_FROM_CARD_DIALOG = 'START_DELETING_CARD_FROM_CARD_DIALOG'
export const STOP_DELETING_CARD_FROM_CARD_DIALOG = 'STOP_DELETING_CARD_FROM_CARD_DIALOG'
export const SHOW_CARD_DIALOG_COLOR_PICKER = 'SHOW_CARD_DIALOG_COLOR_PICKER'
export const HIDE_CARD_DIALOG_COLOR_PICKER = 'HIDE_CARD_DIALOG_COLOR_PICKER'
export const SHOW_CARD_DIALOG_TEMPLATE_PICKER = 'SHOW_CARD_DIALOG_TEMPLATE_PICKER'
export const HIDE_CARD_DIALOG_TEMPLATE_PICKER = 'HIDE_CARD_DIALOG_TEMPLATE_PICKER'
export const START_REMOVING_TEMPLATE_FROM_CARD_DIALOG = 'START_REMOVING_TEMPLATE_FROM_CARD_DIALOG'
export const STOP_REMOVING_TEMPLATE_FROM_CARD_DIALOG = 'STOP_REMOVING_TEMPLATE_FROM_CARD_DIALOG'
export const SET_ACTIVE_TAB_ON_CARD_DIALOG = 'SET_ACTIVE_TAB_ON_CARD_DIALOG'
// places tab
export const SHOW_PLACE_ATTRIBUTE_DIALOG = 'SHOW_PLACE_ATTRIBUTE_DIALOG'
export const HIDE_PLACE_ATTRIBUTE_DIALOG = 'HIDE_PLACE_ATTRIBUTE_DIALOG'
export const START_EDITING_SELECTED_PLACE = 'START_EDITING_SELECTED_PLACE'
export const FINISH_EDITING_SELECTED_PLACE = 'FINISH_EDITING_SELECTED_PLACE'
export const SHOW_PLACE_CATETORIES_MODAL = 'SHOW_PLACE_CATETORIES_MODAL'
export const HIDE_PLACE_CATETORIES_MODAL = 'HIDE_PLACE_CATETORIES_MODAL'
export const SHOW_PLACE_FILTER_LIST = 'SHOW_PLACE_FILTER_LIST'
export const HIDE_PLACE_FILTER_LIST = 'HIDE_PLACE_FILTER_LIST'
export const SHOW_PLACE_SORT = 'SHOW_PLACE_SORT'
export const HIDE_PLACE_SORT = 'HIDE_PLACE_SORT'
// notes tab
export const START_EDITING_SELECTED_NOTE = 'START_EDITING_SELECTED_NOTE'
export const FINISH_EDITING_SELECTED_NOTE = 'FINISH_EDITING_SELECTED_NOTE'
export const SHOW_NOTES_CATEGORY_DIALOG = 'OPEN_NOTES_CATEGORY_DIALOG'
export const HIDE_NOTES_CATEGORY_DIALOG = 'HIDE_NOTES_CATEGORY_DIALOG'
export const SHOW_NOTES_ATTRIBUTES_DIALOG = 'SHOW_NOTES_ATTRIBUTES_DIALOG'
export const HIDE_NOTES_ATTRIBUTES_DIALOG = 'HIDE_NOTES_ATTRIBUTES_DIALOG'
export const SHOW_NOTES_FILTER_LIST = 'SHOW_NOTES_FILTER_LIST'
export const HIDE_NOTES_FILTER_LIST = 'HIDE_NOTES_FILTER_LIST'
export const SHOW_NOTES_SORT = 'SHOW_NOTES_SORT'
export const HIDE_NOTES_SORT = 'HIDE_NOTES_SORT'
// characters tab
export const SHOW_CHARACTERS_ATTRIBUTES_DIALOG = 'SHOW_CHARACTERS_ATTRIBUTES_DIALOG'
export const HIDE_CHARACTERS_ATTRIBUTES_DIALOG = 'HIDE_CHARACTERS_ATTRIBUTES_DIALOG'
export const SHOW_CHARACTERS_CATEGORIES_DIALOG = 'SHOW_CHARACTERS_CATEGORIES_DIALOG'
export const HIDE_CHARACTERS_CATEGORIES_DIALOG = 'HIDE_CHARACTERS_CATEGORIES_DIALOG'
export const START_EDITING_SELECTED_CHARACTER = 'START_EDITING_SELECTED_CHARACTER'
export const FINISH_EDITING_SELECTED_CHARACTER = 'FINISH_EDITING_SELECTED_CHARACTER'
export const SHOW_CHARACTERS_TEMPLATE_PICKER = 'SHOW_CHARACTERS_TEMPLATE_PICKER'
export const HIDE_CHARACTERS_TEMPLATE_PICKER = 'HIDE_CHARACTERS_TEMPLATE_PICKER'
export const START_CREATING_CHARACTER = 'START_CREATING_CHARACTER'
export const FINISH_CREATING_CHARACTER = 'FINISH_CREATING_CHARACTER'
export const SET_CHARACTER_TEMPLATE_DATA = 'SET_CHARACTER_TEMPLATE_DATA'
export const SHOW_CHARACTER_FILTER = 'SHOW_CHARACTER_FILTER'
export const HIDE_CHARACTER_FILTER = 'HIDE_CHARACTER_FILTER'
export const SHOW_CHARACTER_SORT = 'SHOW_CHARACTER_SORT'
export const HIDE_CHARACTER_SORT = 'HIDE_CHARACTER_SORT'
export const SHOW_CHARACTER_DETAILS = 'SHOW_CHARACTER_DETAILS'
export const HIDE_CHARACTER_DETAILS = 'HIDE_CHARACTER_DETAILS'
// character tab, character editor
export const START_DELETING_CHARACTER = 'START_DELETING_CHARACTER'
export const FINISH_DELETING_CHARACTER = 'FINISH_DELETING_CHARACTER'
export const START_REMOVING_TEMPLATE_FROM_CHARACTER = 'START_REMOVING_TEMPLATE_FROM_CHARACTER'
export const FINISH_REMOVING_TEMPLATE_FROM_CHARACTER = 'FINISH_REMOVING_TEMPLATE_FROM_CHARACTER'
export const SET_TEMPLATE_TO_REMOVE_FROM_CHARACTER = 'SET_TEMPLATE_TO_REMOVE_FROM_CHARACTER'
export const SET_ACTIVE_CHARACTER_TAB = 'SET_ACTIVE_CHARACTER_TAB'
export const SHOW_CHARACTER_EDITOR_TEMPLATE_PICKER = 'SHOW_CHARACTER_EDITOR_TEMPLATE_PICKER'
export const HIDE_CHARACTER_EDITOR_TEMPLATE_PICKER = 'HIDE_CHARACTER_EDITOR_TEMPLATE_PICKER'
// outline card editor
export const START_EDITING_OUTLINE_CARD = 'START_EDITING_OUTLINE_CARD'
export const FINISH_EDITING_OUTLINE_CARD = 'FINISH_EDITING_OUTLINE_CARD'
export const TOGGLE_ADVANCED_SAVE_TEMPLATE_PANEL = 'TOGGLE_ADVANCED_SAVE_TEMPLATE_PANEL'
export const SET_FOCUSSED_TIMELINE_TAB_BEAT = 'SET_FOCUSSED_TIMELINE_TAB_BEAT'
export const SET_TIMELINE_TAB_BEAT_TO_DELETE = 'SET_TIMELINE_TAB_BEAT_TO_DELETE'
export const SET_ACT_CONFIG_MODAL_OPEN = 'SET_ACT_CONFIG_MODAL_OPEN'
export const SET_EDITING_BEAT_ID = 'SET_EDITING_BEAT_ID'
export const OPEN_RESTRUCTURE_TIMELINE_MODAL = 'OPEN_RESTRUCTURE_TIMELINE_MODAL'
export const CLOSE_RESTRUCTURE_TIMELINE_MODAL = 'CLOSE_RESTRUCTURE_TIMELINE_MODAL'
export const PUSH_FOCUS = 'PUSH_FOCUS'
export const TOGGLE_REPLACE_SEARCH = 'TOGGLE_REPLACE_SEARCH'
export const SET_REPLACEMENT_TEXT = 'SET_REPLACEMENT_TEXT'
export const OPEN_REPLACE = 'OPEN_REPLACE'
export const TOGGLE_HIT_MARKED_FOR_REPLACEMENT = 'TOGGLE_HIT_MARKED_FOR_REPLACEMENT'
export const REPLACE_MARKED_HITS = 'REPLACE_MARKED_HITS'
export const UPDATE_HITS_MARKED_FOR_REPLACEMENT = 'UPDATE_HITS_MARKED_FOR_REPLACEMENT'
export const START_SCANNING_SEARCH = 'START_SCANNING_SEARCH'
export const START_EDITING_BEAT_HEADING_TITLE = 'START_EDITING_BEAT_HEADING_TITLE'
export const STOP_EDITING_BEAT_HEADING_TITLE = 'STOP_EDITING_BEAT_HEADING_TITLE'
export const START_EDITING_PLOTLINE_HEADING_TITLE = 'START_EDITING_PLOTLINE_HEADING_TITLE'
export const STOP_EDITING_PLOTLINE_HEADING_TITLE = 'STOP_EDITING_PLOTLINE_HEADING_TITLE'
export const START_JUMPING = 'START_JUMPING'
export const FINISH_JUMPING = 'FINISH_JUMPING'
export const SET_REPLACE_WORD = 'SET_REPLACE_WORD'
export const SET_DASHBOARD_MODAL_VIEW = 'SET_DASHBOARD_MODAL_VIEW'

// beat actions
export const ADD_BEAT = 'ADD_BEAT'
export const EDIT_BEAT_TITLE = 'EDIT_BEAT_TITLE'
export const REORDER_BEATS = 'REORDER_BEATS'
export const DELETE_BEAT = 'DELETE_BEAT'
export const AUTO_SORT_BEAT = 'AUTO_SORT_BEAT'
export const INSERT_BEAT = 'INSERT_BEAT'
export const EXPAND_BEAT = 'EXPAND_BEAT'
export const COLLAPSE_BEAT = 'COLLAPSE_BEAT'
export const LOAD_BEATS = 'LOAD_BEATS'
export const APPEND_TOP_LEVEL_BEAT = 'APPEND_TOP_LEVEL_BEAT'
export const RESTRUCTURE_TIMELINE = 'RESTRUCTURE_TIMELINE'
export const UNSAFE_SET_BEATS = 'UNSAFE_SET_BEATS'

// card actions
export const ADD_CARD = 'ADD_CARD'
export const ADD_CARD_IN_BEAT = 'ADD_CARD_IN_BEAT'
export const EDIT_CARD_DETAILS = 'EDIT_CARD_DETAILS'
export const EDIT_CARD_TEMPLATE_ATTRIBUTE = 'EDIT_CARD_TEMPLATE_ATTRIBUTE'
export const EDIT_CARD_COORDINATES = 'EDIT_CARD_COORDINATES'
export const DELETE_CARD = 'DELETE_CARD'
export const CHANGE_LINE = 'CHANGE_STORYLINE'
export const CHANGE_BEAT = 'CHANGE_BEAT'
export const CHANGE_BOOK = 'CHANGE_BOOK'
export const REORDER_CARDS_WITHIN_LINE = 'REORDER_CARDS_WITHIN_LINE'
export const REORDER_CARDS_IN_BEAT = 'REORDER_CARDS_IN_BEAT'
export const ATTACH_CHARACTER_TO_CARD = 'ATTACH_CHARACTER_TO_CARD'
export const ATTACH_PLACE_TO_CARD = 'ATTACH_PLACE_TO_CARD'
export const ATTACH_TAG_TO_CARD = 'ATTACH_TAG_TO_CARD'
export const REMOVE_CHARACTER_FROM_CARD = 'REMOVE_CHARACTER_FROM_CARD'
export const REMOVE_PLACE_FROM_CARD = 'REMOVE_PLACE_FROM_CARD'
export const REMOVE_TAG_FROM_CARD = 'REMOVE_TAG_FROM_CARD'
export const LOAD_CARDS = 'LOAD_CARDS'
export const LOAD_CARD = 'LOAD_CARD'
export const BATCH_LOAD_CARD = 'BATCH_LOAD_CARD'
export const REMOVE_CARD = 'REMOVE_CARD'
export const ADD_TEMPLATE_TO_CARD = 'ADD_TEMPLATE_TO_CARD'
export const REMOVE_TEMPLATE_FROM_CARD = 'REMOVE_TEMPLATE_FROM_CARD'
export const DUPLICATE_CARD = 'DUPLICATE_CARD'
export const MOVE_CARD_TO_BOOK = 'MOVE_CARD_TO_BOOK'
export const EDIT_CARD_TITLE = 'EDIT_CARD_TITLE'
export const EDIT_CARD_DESCRIPTION = 'EDIT_CARD_DESCRIPTION'
export const EDIT_CARD_CUSTOM_ATTRIBUTE = 'EDIT_CARD_CUSTOM_ATTRIBUTE'
export const REORDER_CARD_TEMPLATE_ATTRIBUTES = 'REORDER_CARD_TEMPLATE_ATTRIBUTES'
export const REORDER_OUTLINE_PLAN_CARDS = 'REORDER_OUTLINE_PLAN_CARDS'
// Conflict resulotion dialog
export const CLOSE_CONFLICT_RESOLUTION_MODAL = 'CLOSE_CONFLICT_RESOLUTION_MODAL'

// line actions
export const ADD_LINE = 'ADD_STORYLINE'
export const ADD_LINE_WITH_TITLE = 'ADD_LINE_WITH_TITLE'
export const ADD_LINES_FROM_TEMPLATE = 'ADD_LINES_FROM_TEMPLATE'
export const EDIT_LINE = 'EDIT_LINE'
export const EDIT_LINE_TITLE = 'EDIT_STORYLINE_TITLE'
export const EDIT_LINE_COLOR = 'EDIT_STORYLINE_COLOR'
export const REORDER_LINES = 'REORDER_STORYLINES'
export const DELETE_LINE = 'DELETE_STORYLINE'
export const EXPAND_LINE = 'EXPAND_LINE'
export const COLLAPSE_LINE = 'COLLAPSE_LINE'
export const DUPLICATE_LINE = 'DUPLICATE_LINE'
export const LOAD_LINES = 'LOAD_LINES'
export const MOVE_LINE = 'MOVE_LINE'
export const PIN_PLOTLINE = 'PIN_PLOTLINE'
export const UNPIN_PLOTLINE = 'UNPIN_PLOTLINE'
export const APPLY_TIMELINE_TEMPLATE = 'APPLY_TIMELINE_TEMPLATE'

// places actions
export const ADD_PLACE = 'ADD_PLACE'
export const ADD_PLACE_WITH_VALUES = 'ADD_PLACE_WITH_VALUES'
export const EDIT_PLACE = 'EDIT_PLACE'
export const EDIT_PLACE_TEMPLATE_ATTRIBUTE = 'EDIT_PLACE_TEMPLATE_ATTRIBUTE'
export const DELETE_PLACE = 'DELETE_PLACE'
export const ATTACH_TAG_TO_PLACE = 'ATTACH_TAG_TO_PLACE'
export const ATTACH_BOOK_TO_PLACE = 'ATTACH_BOOK_TO_PLACE'
export const REMOVE_TAG_FROM_PLACE = 'REMOVE_TAG_FROM_PLACE'
export const REMOVE_BOOK_FROM_PLACE = 'REMOVE_BOOK_FROM_PLACE'
export const LOAD_PLACES = 'LOAD_PLACES'
export const LOAD_PLACE = 'LOAD_PLACE'
export const BATCH_LOAD_PLACE = 'BATCH_LOAD_PLACE'
export const REMOVE_PLACE = 'REMOVE_PLACE'
export const DUPLICATE_PLACE = 'DUPLICATE_PLACE'
export const ADD_PLACE_FROM_PLTR = 'ADD_PLACE_FROM_PLTR'
export const EDIT_PLACE_NAME = 'EDIT_PLACE_NAME'
export const EDIT_PLACE_DESCRIPTION = 'EDIT_PLACE_DESCRIPTION'
export const EDIT_PLACE_NOTES = 'EDIT_PLACE_NOTES'
export const EDIT_PLACE_CUSTOM_ATTRIBUTE = 'EDIT_PLACE_CUSTOM_ATTRIBUTE'
export const REORDER_PLACE_MANUALLY = 'REORDER_PLACE_MANUALLY'

// tags actions
export const ADD_TAG = 'ADD_TAG'
export const ADD_TAG_WITH_VALUES = 'ADD_TAG_WITH_VALUES'
export const ADD_CREATED_TAG = 'ADD_CREATED_TAG'
export const EDIT_TAG = 'EDIT_TAG'
export const CHANGE_TAG_COLOR = 'CHANGE_TAG_COLOR'
export const DELETE_TAG = 'DELETE_TAG'
export const LOAD_TAGS = 'LOAD_TAGS'
export const DUPLICATE_TAG = 'DUPLICATE_TAG'
export const ADD_TAG_FROM_PLTR = 'ADD_TAG_FROM_PLTR'
export const EDIT_SELECTED_TAG = 'EDIT_SELECTED_TAG'
export const FINISH_EDITING_SELECTED_TAG = 'FINISH_EDITING_SELECTED_TAG'

// characters actions
export const ADD_CHARACTER = 'ADD_CHARACTER'
export const ADD_CHARACTER_WITH_TEMPLATE = 'ADD_CHARACTER_WITH_TEMPLATE'
export const EDIT_CHARACTER = 'EDIT_CHARACTER'
export const EDIT_CHARACTER_TEMPLATE_ATTRIBUTE = 'EDIT_CHARACTER_TEMPLATE_ATTRIBUTE'
export const DELETE_CHARACTER = 'DELETE_CHARACTER'
export const ATTACH_TAG_TO_CHARACTER = 'ATTACH_TAG_TO_CHARACTER'
export const ATTACH_BOOK_TO_CHARACTER = 'ATTACH_BOOK_TO_CHARACTER'
export const REMOVE_TAG_FROM_CHARACTER = 'REMOVE_TAG_FROM_CHARACTER'
export const REMOVE_BOOK_FROM_CHARACTER = 'REMOVE_BOOK_FROM_CHARACTER'
export const LOAD_CHARACTERS = 'LOAD_CHARACTERS'
export const LOAD_CHARACTER = 'LOAD_CHARACTER'
export const BATCH_LOAD_CHARACTER = 'BATCH_LOAD_CHARACTER'
export const REMOVE_CHARACTER = 'REMOVE_CHARACTER'
export const ADD_TEMPLATE_TO_CHARACTER = 'ADD_TEMPLATE_TO_CHARACTER'
export const REORDER_CHARACTER_TEMPLATES = 'REORDER_CHARACTER_TEMPLATES'
export const REMOVE_TEMPLATE_FROM_CHARACTER = 'REMOVE_TEMPLATE_FROM_CHARACTER'
export const DUPLICATE_CHARACTER = 'DUPLICATE_CHARACTER'
export const ADD_CHARACTER_FROM_PLTR = 'ADD_CHARACTER_FROM_PLTR'
export const CREATE_CHARACTER_ATTRIBUTE = 'CREATE_CHARACTER_ATTRIBUTE'
export const EDIT_CHARACTER_ATTRIBUTE_VALUE = 'EDIT_CHARACTER_ATTRIBUTE_VALUE'
export const EDIT_CHARACTER_ATTRIBUTE_METADATA = 'EDIT_CHARACTER_ATTRIBUTE_METADATA'
export const DELETE_CHARACTER_ATTRIBUTE = 'DELETE_CHARACTER_ATTRIBUTE'
export const REORDER_CHARACTER_ATTRIBUTE_METADATA = 'REORDER_CHARACTER_ATTRIBUTE_METADATA'
export const EDIT_CHARACTER_SHORT_DESCRIPTION = 'EDIT_CHARACTER_SHORT_DESCRIPTION'
export const EDIT_CHARACTER_DESCRIPTION = 'EDIT_CHARACTER_DESCRIPTION'
export const EDIT_CHARACTER_CATEGORY = 'EDIT_CHARACTER_CATEGORY'
export const EDIT_CHARACTER_NAME = 'EDIT_CHARACTER_NAME'
export const EDIT_CHARACTER_IMAGE = 'EDIT_CHARACTER_IMAGE'
export const DELETE_CHARACTER_LEGACY_CUSTOM_ATTRIBUTE = 'DELETE_CHARACTER_LEGACY_CUSTOM_ATTRIBUTE'
export const REORDER_CHARACTER_MANUALLY = 'REORDER_CHARACTER_MANUALLY'

// attributes actions
export const LOAD_ATTRIBUTES = 'LOAD_ATTRIBUTES'

// notes actions
export const ADD_NOTE = 'ADD_NOTE'
export const EDIT_NOTE = 'EDIT_NOTE'
export const EDIT_NOTE_TEMPLATE_ATTRIBUTE = 'EDIT_NOTE_TEMPLATE_ATTRIBUTE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const ATTACH_CHARACTER_TO_NOTE = 'ATTACH_CHARACTER_TO_NOTE'
export const ATTACH_PLACE_TO_NOTE = 'ATTACH_PLACE_TO_NOTE'
export const ATTACH_TAG_TO_NOTE = 'ATTACH_TAG_TO_NOTE'
export const ATTACH_BOOK_TO_NOTE = 'ATTACH_BOOK_TO_NOTE'
export const REMOVE_CHARACTER_FROM_NOTE = 'REMOVE_CHARACTER_FROM_NOTE'
export const REMOVE_PLACE_FROM_NOTE = 'REMOVE_PLACE_FROM_NOTE'
export const REMOVE_TAG_FROM_NOTE = 'REMOVE_TAG_FROM_NOTE'
export const REMOVE_BOOK_FROM_NOTE = 'REMOVE_BOOK_FROM_NOTE'
export const SET_NOTES_SEARCH_TERM = 'SET_NOTES_SEARCH_TERM'
export const SET_CHARACTERS_SEARCH_TERM = 'SET_CHARACTERS_SEARCH_TERM'
export const SET_PLACES_SEARCH_TERM = 'SET_PLACES_SEARCH_TERM'
export const SET_TAGS_SEARCH_TERM = 'SET_TAGS_SEARCH_TERM'
export const SET_OUTLINE_SEARCH_TERM = 'SET_OUTLINE_SEARCH_TERM'
export const SET_TIMELINE_SEARCH_TERM = 'SET_TIMELINE_SEARCH_TERM'
export const LOAD_NOTES = 'LOAD_NOTES'
export const LOAD_NOTE = 'LOAD_NOTE'
export const BATCH_LOAD_NOTE = 'BATCH_LOAD_NOTE'
export const REMOVE_NOTE = 'REMOVE_NOTE'
export const DUPLICATE_NOTE = 'DUPLICATE_NOTE'
export const ADD_NOTE_FROM_PLTR = 'ADD_NOTE_FROM_PLTR'
export const EDIT_NOTE_TITLE = 'EDIT_NOTE_TITLE'
export const EDIT_NOTE_CONTENT = 'EDIT_NOTE_CONTENT'
export const EDIT_NOTE_CUSTOM_ATTRIBUTE = 'EDIT_NOTE_CUSTOM_ATTRIBUTE'
export const REORDER_NOTE_MANUALLY = 'REORDER_NOTE_MANUALLY'

// custom attribute actions
export const ADD_PLACES_ATTRIBUTE = 'ADD_PLACES_ATTRIBUTE'
export const ADD_CARDS_ATTRIBUTE = 'ADD_CARDS_ATTRIBUTE'
export const ADD_LINES_ATTRIBUTE = 'ADD_LINES_ATTRIBUTE'
export const ADD_NOTES_ATTRIBUTE = 'ADD_NOTES_ATTRIBUTE'

export const REMOVE_PLACES_ATTRIBUTE = 'REMOVE_PLACES_ATTRIBUTE'
export const REMOVE_CARDS_ATTRIBUTE = 'REMOVE_CARDS_ATTRIBUTE'
export const REMOVE_LINES_ATTRIBUTE = 'REMOVE_LINES_ATTRIBUTE'
export const REMOVE_NOTES_ATTRIBUTE = 'REMOVE_NOTES_ATTRIBUTE'

export const EDIT_CHARACTER_ATTRIBUTE = 'EDIT_CHARACTER_ATTRIBUTE'
export const EDIT_PLACES_ATTRIBUTE = 'EDIT_PLACES_ATTRIBUTE'
export const EDIT_CARDS_ATTRIBUTE = 'EDIT_CARDS_ATTRIBUTE'
export const EDIT_LINES_ATTRIBUTE = 'EDIT_LINES_ATTRIBUTE'
export const EDIT_NOTES_ATTRIBUTE = 'EDIT_NOTES_ATTRIBUTE'

export const REORDER_CHARACTER_ATTRIBUTE = 'REORDER_CHARACTER_ATTRIBUTE'
export const REORDER_PLACES_ATTRIBUTE = 'REORDER_PLACES_ATTRIBUTE'
export const REORDER_CARDS_ATTRIBUTE = 'REORDER_CARDS_ATTRIBUTE'
export const REORDER_LINES_ATTRIBUTE = 'REORDER_LINES_ATTRIBUTE'
export const REORDER_NOTES_ATTRIBUTE = 'REORDER_NOTES_ATTRIBUTE'

export const CUSTOM_ATTRIBUTE_ERROR = 'CUSTOM_ATTRIBUTE_ERROR'

export const LOAD_CUSTOM_ATTRIBUTES = 'LOAD_CUSTOM_ATTRIBUTES'

// image actions
export const ADD_IMAGE = 'ADD_IMAGE'
export const ADD_IMAGE_FROM_PLTR = 'ADD_IMAGE_FROM_PLTR'
export const RENAME_IMAGE = 'RENAME_IMAGE'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const LOAD_IMAGES = 'LOAD_IMAGES'

// series actions
export const EDIT_SERIES = 'EDIT_SERIES'
export const SET_SERIES_NAME = 'SET_SERIES_NAME'
export const SET_SERIES_PREMISE = 'SET_SERIES_PREMISE'
export const SET_SERIES_GENRE = 'SET_SERIES_GENRE'
export const SET_SERIES_THEME = 'SET_SERIES_THEME'
export const LOAD_SERIES = 'LOAD_SERIES'

// book actions
export const ADD_BOOK = 'ADD_BOOK'
export const ADD_BOOK_FROM_TEMPLATE = 'ADD_BOOK_FROM_TEMPLATE'
export const EDIT_BOOK = 'EDIT_BOOK'
export const EDIT_BOOK_IMAGE = 'EDIT_BOOK_IMAGE'
export const DELETE_BOOK = 'DELETE_BOOK'
export const REORDER_BOOKS = 'REORDER_BOOKS'
export const LOAD_BOOKS = 'LOAD_BOOKS'
export const SET_BOOK_TITLE = 'SET_BOOK_TITLE'
export const SET_BOOK_PREMISE = 'SET_BOOK_PREMISE'
export const SET_BOOK_GENRE = 'SET_BOOK_GENRE'
export const SET_BOOK_THEME = 'SET_BOOK_THEME'
export const DUPLICATE_BOOK = 'DUPLICATE_BOOK'

// category actions
export const ADD_CHARACTER_CATEGORY = 'ADD_CHARACTER_CATEGORY'
export const DELETE_CHARACTER_CATEGORY = 'DELETE_CHARACTER_CATEGORY'
export const UPDATE_CHARACTER_CATEGORY = 'UPDATE_CHARACTER_CATEGORY'
export const REORDER_CHARACTER_CATEGORY = 'REORDER_CHARACTER_CATEGORY'
export const ADD_NOTE_CATEGORY = 'ADD_NOTE_CATEGORY'
export const DELETE_NOTE_CATEGORY = 'DELETE_NOTE_CATEGORY'
export const UPDATE_NOTE_CATEGORY = 'UPDATE_NOTE_CATEGORY'
export const REORDER_NOTE_CATEGORY = 'REORDER_NOTE_CATEGORY'
export const ADD_TAG_CATEGORY = 'ADD_TAG_CATEGORY'
export const DELETE_TAG_CATEGORY = 'DELETE_TAG_CATEGORY'
export const UPDATE_TAG_CATEGORY = 'UPDATE_TAG_CATEGORY'
export const REORDER_TAG_CATEGORY = 'REORDER_TAG_CATEGORY'
export const ADD_PLACE_CATEGORY = 'ADD_PLACE_CATEGORY'
export const DELETE_PLACE_CATEGORY = 'DELETE_PLACE_CATEGORY'
export const UPDATE_PLACE_CATEGORY = 'UPDATE_PLACE_CATEGORY'
export const REORDER_PLACE_CATEGORY = 'REORDER_PLACE_CATEGORY'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'

// hierarchy actions
export const SET_HIERARCHY_LEVELS = 'SET_HIERARCHY_LEVELS'
export const EDIT_HIERARCHY_LEVEL = 'EDIT_HIERARCHY_LEVEL'
export const LOAD_HIERARCHY = 'LOAD_HIERARCHY'

// feature flags
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG'
export const UNSET_FEATURE_FLAG = 'UNSET_FEATURE_FLAG'
export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS'

// error actions
export const PERMISSION_ERROR = 'PERMISSION_ERROR'
export const GENERAL_ERROR = 'GENERAL_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const IMPORT_ERROR = 'IMPORT_ERROR'
export const SAVE_TEMP_FILE_ERROR = 'SAVE_TEMP_FILE_ERROR'
// IMPORTANT: Please keep this up to date(!).  Impacts our ability to
// track actual changes to the content of a file as apposed to system
// data.
export const ERROR_ACTIONS = [
  PERMISSION_ERROR,
  GENERAL_ERROR,
  CLEAR_ERROR,
  IMPORT_ERROR,
  SAVE_TEMP_FILE_ERROR,
]

// project actions
export const SET_FILE_LIST = 'SET_FILE_LIST'
export const SELECT_FILE = 'SELECT_FILE'
export const SELECT_EMPTY_FILE = 'SELECT_EMPTY_FILE'
export const UNSET_FILE_LOADED = 'UNSET_FILE_LOADED'
export const SHOW_LOADER = 'SHOW_LOADER'
export const SET_OFFLINE = 'SET_OFFLINE'
export const SET_RESUMING = 'SET_RESUMING'
export const SET_CHECKING_FOR_OFFLINE_DRIFT = 'SET_CHECKING_FOR_OFFLINE_DRIFT'
export const SET_OVERWRITING_CLOUD_WITH_BACKUP = 'SET_OVERWRITING_CLOUD_WITH_BACKUP'
export const SET_SHOW_RESUME_MESSAGE_DIALOG = 'SET_SHOW_RESUME_MESSAGE_DIALOG'
export const SET_BACKING_UP_OFFLINE_FILE = 'SET_BACKING_UP_OFFLINE_FILE'
export const START_CREATING_NEW_PROJECT = 'START_CREATING_NEW_PROJECT'
export const FINISH_CREATING_NEW_PROJECT = 'FINISH_CREATING_NEW_PROJECT'
export const SAVE_IMPORT_PLTR_DATA = 'SAVE_IMPORT_PLTR_DATA'
export const ADD_BOOK_FROM_PLTR = 'ADD_BOOK_FROM_PLTR'
export const SET_CONFLICT_RESOLUTION_DATA = 'SET_CONFLICT_RESOLUTION_DATA'
export const SET_CONFLICT_RESOLUTION_ITEM_ACTION = 'SET_CONFLICT_RESOLUTION_ITEM_ACTION'
export const ACTION_CONFLICT_CHANGES = 'ACTION_CONFLICT_CHANGES'
export const BOOTED_OFFLINE_FILE_WHILST_ONLINE = 'BOOTED_OFFLINE_FILE'
// IMPORTANT: Please keep this up to date(!).  Impacts our ability to
// track actual changes to the content of a file as apposed to system
// data.
export const PROJECT_ACTIONS = [
  SET_FILE_LIST,
  SELECT_FILE,
  SELECT_EMPTY_FILE,
  UNSET_FILE_LOADED,
  SHOW_LOADER,
  SET_OFFLINE,
  SET_RESUMING,
  SET_CHECKING_FOR_OFFLINE_DRIFT,
  SET_OVERWRITING_CLOUD_WITH_BACKUP,
  SET_SHOW_RESUME_MESSAGE_DIALOG,
  SET_BACKING_UP_OFFLINE_FILE,
  START_CREATING_NEW_PROJECT,
  FINISH_CREATING_NEW_PROJECT,
  SAVE_IMPORT_PLTR_DATA,
  ADD_BOOK_FROM_PLTR,
  SET_CONFLICT_RESOLUTION_DATA,
  SET_CONFLICT_RESOLUTION_ITEM_ACTION,
  ACTION_CONFLICT_CHANGES,
  BOOTED_OFFLINE_FILE_WHILST_ONLINE,
]

// client actions
export const SET_USER_ID = 'SET_USER_ID'
export const SET_CLIENT_ID = 'SET_CLIENT_ID'
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS'
export const SET_HAS_ONBOARDED = 'SET_HAS_ONBOARDED'
export const SET_IS_ON_WEB = 'SET_IS_ON_WEB'
export const SET_CURRENT_APP_STATE = 'SET_CURRENT_APP_STATE'
export const RECORD_DATA_CLIENT_ID = 'RECORD_DATA_CLIENT_ID'
export const RECORD_DATA_CLIENT_IDS = 'RECORD_DATA_CLIENT_IDS'
// IMPORTANT: Please keep this up to date(!).  Impacts our ability to
// track actual changes to the content of a file as apposed to system
// data.
export const CLIENT_ACTIONS = [
  SET_USER_ID,
  SET_CLIENT_ID,
  SET_EMAIL_ADDRESS,
  SET_HAS_ONBOARDED,
  SET_IS_ON_WEB,
  SET_CURRENT_APP_STATE,
  RECORD_DATA_CLIENT_ID,
  RECORD_DATA_CLIENT_IDS,
]

// action actions (for undo)
export const RECORD_LAST_ACTION = 'RECORD_LAST_ACTION'
export const RESET_ACTION_RECORDER = 'RESET_ACTION_RECORDER'
// IMPORTANT: Please keep this up to date(!).  Impacts our ability to
// track actual changes to the content of a file as apposed to system
// data.
export const ACTION_ACTIONS = [RECORD_LAST_ACTION, RESET_ACTION_RECORDER]

// License actions
export const SET_TRIAL_INFO = 'SET_TRIAL_INFO'
export const SET_LICENSE_INFO = 'SET_LICENSE_INFO'
export const SET_LICENSE_CHECK_INTERVAL_ID = 'SET_LICENSE_CHECK_INTERVAL_ID'
export const LICENSE_ACTIONS = [SET_TRIAL_INFO, SET_LICENSE_INFO, SET_LICENSE_CHECK_INTERVAL_ID]

// editor actions

// Known files
export const SET_KNOWN_FILES = 'SET_KNOWN_FILES'
export const KNOWN_FILES_ACTIONS = [SET_KNOWN_FILES]

// Templates
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const SET_CUSTOM_TEMPLATES = 'SET_CUSTOM_TEMPLATES'
export const SET_TEMPLATE_MANIFEST = 'SET_TEMPLATE_MANIFEST'
export const TEMPLATE_ACTIONS = [SET_TEMPLATES, SET_CUSTOM_TEMPLATES, SET_TEMPLATE_MANIFEST]

// Settings
export const SET_EXPORT_SETTINGS = 'SET_EXPORT_SETTINGS'
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS'
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SETTINGS_ACTIONS = [
  SET_EXPORT_SETTINGS,
  SET_USER_SETTINGS,
  SET_APP_SETTINGS,
  SET_DARK_MODE,
]

// Backups
export const SET_BACKUP_FOLDERS = 'SET_BACKUP_FOLDERS'
export const BACKUP_ACTIONS = [SET_BACKUP_FOLDERS]

// Application State
export const START_LOADING_FILE_LIST = 'START_LOADING_FILE_LIST'
export const FINISH_LOADING_FILE_LIST = 'FINISH_LOADING_FILE_LIST'
export const START_CREATING_CLOUD_FILE = 'START_CREATING_CLOUD_FILE'
export const FINISH_CREATING_CLOUD_FILE = 'FINISH_CREATING_CLOUD_FILE'
export const START_UPLOADING_FILE_TO_CLOUD = 'START_UPLOADING_FILE_TO_CLOUD'
export const FINISH_UPLOADING_FILE_TO_CLOUD = 'FINISH_UPLOADING_FILE_TO_CLOUD'
export const START_CHECKING_FILE_TO_LOAD = 'START_CHECKING_FILE_TO_LOAD'
export const FINISH_CHECKING_FILE_TO_LOAD = 'FINISH_CHECKING_FILE_TO_LOAD'
export const START_LOADING_FILE = 'START_LOADING_FILE'
export const FINISH_LOADING_FILE = 'FINISH_LOADING_FILE'
export const ERROR_LOADING_FILE = 'ERROR_LOADING_FILE'
export const CLEAR_ERROR_LOADING_FILE = 'CLEAR_ERROR_LOADING_FILE'
export const START_RENAMING_FILE = 'START_RENAMING_FILE'
export const FINISH_RENAMING_FILE = 'FINISH_RENAMING_FILE'
export const START_DELETING_FILE = 'START_DELETING_FILE'
export const FINISH_DELETING_FILE = 'FINISH_DELETING_FILE'
export const START_SAVING_FILE_AS = 'START_SAVING_FILE_AS'
export const FINISH_SAVING_FILE_AS = 'FINISH_SAVING_FILE_AS'
export const START_LOGGING_IN = 'START_LOGGING_IN'
export const FINISH_LOGGING_IN = 'FINISH_LOGGING_IN'
export const START_CHECKING_SESSION = 'START_CHECKING_SESSION'
export const FINISH_CHECKING_SESSION = 'FINISH_CHECKING_SESSION'
export const START_LOADING_A_LICENSE_TYPE = 'START_LOADING_A_LICENSE_TYPE'
export const FINISH_LOADING_A_LICENSE_TYPE = 'FINISH_LOADING_A_LICENSE_TYPE'
export const START_LOADING_A_SETTINGS_TYPE = 'START_LOADING_A_SETTINGS_TYPE'
export const FINISH_LOADING_A_SETTINGS_TYPE = 'FINISH_LOADING_A_SETTINGS_TYPE'
export const ADVANCE_PRO_ONBOARDING = 'ADVANCE_PRO_ONBOARDING'
export const START_ONBOARDING = 'START_ONBOARDING'
export const FINISH_ONBOARDING = 'FINISH_ONBOARDING'
export const ADVANCE_SETTINGS_WIZARD = 'ADVANCE_SETTINGS_WIZARD'
export const REGRESS_SETTINGS_WIZARD = 'REGRESS_SETTINGS_WIZARD'
export const START_SETTINGS_WIZARD = 'START_SETTINGS_WIZARD'
export const FINISH_SETTINGS_WIZARD = 'FINISH_SETTINGS_WIZARD'
export const START_ONBOARDING_FROM_ROOT = 'START_ONBOARDING_FROM_ROOT'
export const START_IMPORTING_SCRIVENER = 'START_IMPORTING_SCRIVENER'
export const FINISH_IMPORTING_SCRIVENER = 'FINISH_IMPORTING_SCRIVENER'
export const START_FILE_IMPORT = 'START_FILE_IMPORT'
export const FINISH_FILE_IMPORT = 'FINISH_FILE_IMPORT'
export const PROMPT_TO_UPLOAD_FILE = 'PROMPT_TO_UPLOAD_FILE'
export const DISMISS_PROMPT_TO_UPLOAD_FILE = 'DISMISS_PROMPT_TO_UPLOAD_FILE'
export const REQUEST_CHECK_FOR_UPDATE = 'REQUEST_CHECK_FOR_UPDATE'
export const AUTO_CHECK_FOR_UPDATES = 'AUTO_CHECK_FOR_UPDATES'
export const PROCESS_RESPONSE_TO_REQUEST_UPDATE = 'PROCESS_RESPONSE_TO_REQUEST_UPDATE'
export const DISMISS_UPDATE_NOTIFIER = 'DISMISS_UPDATE_NOTIFIER'
export const SET_UPDATE_DOWNLOAD_PROGRESS = 'SET_UPDATE_DOWNLOAD_PROGRESS'
export const BUSY_WITH_WORK_THAT_PREVENTS_QUITTING = 'BUSY_WITH_WORK_THAT_PREVENTS_QUITTING'
export const DONE_WITH_WORK_THAT_PREVENTS_QUITTING = 'DONE_WITH_WORK_THAT_PREVENTS_QUITTING'
export const INCREMENT_JUMP_COUNTER = 'INCREMENT_JUMP_COUNTER'
export const START_EDITING = 'START_EDITING'
export const START_VIEWING = 'START_VIEWING'
export const START_SEARCHING = 'START_SEARCHING'
export const STAGE_LANGUAGE = 'STAGE_LANGUAGE'
export const RECONNECTED_TO_LICENSING_API = 'RECONNECTED_TO_LICENSING_API'
export const DIFFICULTY_CONTACTING_LICENSING_API = 'DIFFICULTY_CONTACTING_LICENSING_API'
export const SET_PATH_TO_PROJECT = 'SET_PATH_TO_PROJECT'
export const OPEN_DASHBOARD_TO = 'OPEN_DASHBOARD_TO'
export const DASHBOARD_CLOSED = 'DASHBOARD_CLOSED'
export const FINISH_FIRST_TIME_BOOTING = 'FIRST_TIME_BOOTING'
export const START_FETCHING_A_LICENSE_TYPE = 'START_FETCHING_A_LICENSE_TYPE'
export const FINISH_FETCHING_A_LICENSE_TYPE = 'FINISH_FETCHING_A_LICENSE_TYPE'
export const NO_FILE_TO_SHOW = 'NO_FILE_TO_SHOW'
export const APPLICATION_STATE_ACTIONS = [
  START_LOADING_FILE_LIST,
  FINISH_LOADING_FILE_LIST,
  START_CREATING_CLOUD_FILE,
  FINISH_CREATING_CLOUD_FILE,
  START_UPLOADING_FILE_TO_CLOUD,
  FINISH_UPLOADING_FILE_TO_CLOUD,
  START_CHECKING_FILE_TO_LOAD,
  FINISH_CHECKING_FILE_TO_LOAD,
  START_LOADING_FILE,
  FINISH_LOADING_FILE,
  START_RENAMING_FILE,
  FINISH_RENAMING_FILE,
  START_DELETING_FILE,
  FINISH_DELETING_FILE,
  START_SAVING_FILE_AS,
  FINISH_SAVING_FILE_AS,
  START_LOGGING_IN,
  FINISH_LOGGING_IN,
  START_CHECKING_SESSION,
  FINISH_CHECKING_SESSION,
  START_LOADING_A_LICENSE_TYPE,
  FINISH_LOADING_A_LICENSE_TYPE,
  START_LOADING_A_SETTINGS_TYPE,
  FINISH_LOADING_A_SETTINGS_TYPE,
  ADVANCE_PRO_ONBOARDING,
  START_ONBOARDING,
  FINISH_ONBOARDING,
  START_ONBOARDING_FROM_ROOT,
  START_IMPORTING_SCRIVENER,
  FINISH_IMPORTING_SCRIVENER,
  PROMPT_TO_UPLOAD_FILE,
  DISMISS_PROMPT_TO_UPLOAD_FILE,
  REQUEST_CHECK_FOR_UPDATE,
  PROCESS_RESPONSE_TO_REQUEST_UPDATE,
  DISMISS_UPDATE_NOTIFIER,
  SET_UPDATE_DOWNLOAD_PROGRESS,
  BUSY_WITH_WORK_THAT_PREVENTS_QUITTING,
  DONE_WITH_WORK_THAT_PREVENTS_QUITTING,
  INCREMENT_JUMP_COUNTER,
  START_EDITING,
  START_VIEWING,
  START_SEARCHING,
  STAGE_LANGUAGE,
  RECONNECTED_TO_LICENSING_API,
  DIFFICULTY_CONTACTING_LICENSING_API,
  SET_PATH_TO_PROJECT,
  OPEN_DASHBOARD_TO,
  DASHBOARD_CLOSED,
  FINISH_FIRST_TIME_BOOTING,
  START_FETCHING_A_LICENSE_TYPE,
  FINISH_FETCHING_A_LICENSE_TYPE,
  NO_FILE_TO_SHOW,
]

// Image Cache
export const CACHE_IMAGE = 'CACHE_IMAGE'
export const PURGE_IMAGE = 'PURGE_IMAGE'
export const IMAGE_CACHE_ACTIONS = [CACHE_IMAGE, PURGE_IMAGE]

// Notifications
export const SHOW_TOAST_NOTIFICATION = 'SHOW_TOAST_NOTIFICATION'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE'
export const SHOW_GENERIC_NOTIFICATION = 'SHOW_GENERIC_NOTIFICATION'
export const DISMISS_GENERIC_NOTIFICATION = 'DISMISS_GENERIC_NOTIFICATION'
export const NOTIFICATION_ACTIONS = [
  SHOW_TOAST_NOTIFICATION,
  SHOW_MESSAGE,
  DISMISS_MESSAGE,
  SHOW_GENERIC_NOTIFICATION,
  DISMISS_GENERIC_NOTIFICATION,
]

// DOM Events
export const CLICK_ON_DOM = 'CLICK_ON_DOM'
export const DROP_BEAT = 'DROP_BEAT'
export const COLLECT_BEAT = 'COLLECT_BEAT'
export const DOM_EVENT_ACTIONS = [CLICK_ON_DOM, DROP_BEAT, COLLECT_BEAT]

// Testing and diagnosis
export const ENABLE_TEST_UTILITIES = 'ENABLE_TEST_UTILITIES'
export const TESTING_AND_DIAGNOSIS_ACTIONS = [ENABLE_TEST_UTILITIES]

export const SET_OFFLINE_FILES = 'SET_OFFLINE_FILES'
export const SET_CREATED_OFFLINE_FILES = 'SET_CREATED_OFFLINE_FILES'
export const OFFLINE_ACTIONS = [SET_OFFLINE_FILES, SET_CREATED_OFFLINE_FILES]

// Undo/Redo
export const UNDO = 'UNDO'
export const REDO = 'REDO'
export const FORCED_BATCH_START = 'FORCED_BATCH_START'
export const FORCED_BATCH_END = 'FORCED_BATCH_END'
export const DISMISS_RECENT_CHANGE_FLAG = 'DISMISS_RECENT_CHANGE_FLAG'
export const UNDO_N_TIMES = 'UNDO_N_TIMES'
export const REDO_N_TIMES = 'REDO_N_TIMES'
export const UNDO_ACTIONS = [
  UNDO,
  REDO,
  FORCED_BATCH_START,
  FORCED_BATCH_END,
  DISMISS_RECENT_CHANGE_FLAG,
  UNDO_N_TIMES,
  REDO_N_TIMES,
]

// Interesting transaction boundaries etc for undo/redo
export const ACTIONS_THAT_SHOULD_BE_BATCHED = [
  // Cards
  EDIT_CARD_CUSTOM_ATTRIBUTE,
  EDIT_CARD_TEMPLATE_ATTRIBUTE,
  // Lines
  EDIT_LINE_TITLE,
  // Beats
  EDIT_BEAT_TITLE,
  // Places
  EDIT_PLACE_NAME,
  EDIT_PLACE_DESCRIPTION,
  EDIT_PLACE_CUSTOM_ATTRIBUTE,
  EDIT_PLACE_TEMPLATE_ATTRIBUTE,
  // Tags
  EDIT_TAG,
  // Characters
  EDIT_CHARACTER_TEMPLATE_ATTRIBUTE,
  EDIT_CHARACTER_ATTRIBUTE_VALUE,
  EDIT_CHARACTER_SHORT_DESCRIPTION,
  EDIT_CHARACTER_NAME,
  // Notes
  EDIT_NOTE_TITLE,
  EDIT_NOTE_CUSTOM_ATTRIBUTE,
  // Books
  SET_BOOK_TITLE,
  SET_BOOK_PREMISE,
  SET_BOOK_GENRE,
  SET_BOOK_THEME,
  // Series
  SET_SERIES_NAME,
  SET_SERIES_PREMISE,
  SET_SERIES_GENRE,
  SET_SERIES_THEME,
  // Search
  SET_SEARCH_TERM,
  SET_REPLACEMENT_TEXT,
  // Per-tab search
  SET_TIMELINE_SEARCH_TERM,
  SET_OUTLINE_SEARCH_TERM,
  SET_NOTES_SEARCH_TERM,
  SET_CHARACTERS_SEARCH_TERM,
  SET_PLACES_SEARCH_TERM,
  SET_TAGS_SEARCH_TERM,
  // File name
  EDIT_FILENAME,
]

// Depends on whether the attribute type is 'text' (in which case its
// batched) or 'paragraph' (in which case its not batched).
export const ACTION_TYPES_THAT_MIGHT_BE_BATCHED = [
  // Edit card attributes
  EDIT_CARD_TEMPLATE_ATTRIBUTE,
  EDIT_CARD_CUSTOM_ATTRIBUTE,
  // Edit place attributes
  EDIT_PLACE_TEMPLATE_ATTRIBUTE,
  EDIT_PLACE_CUSTOM_ATTRIBUTE,
  // Edit character attributes
  EDIT_CHARACTER_TEMPLATE_ATTRIBUTE,
  EDIT_CHARACTER_ATTRIBUTE_VALUE,
  // Edit note attributes
  EDIT_NOTE_TEMPLATE_ATTRIBUTE,
  EDIT_NOTE_CUSTOM_ATTRIBUTE,
]

// We shouldn't care about certain actions because they always result
// in uninteresting intermediary states.
export const ACTIONS_TO_IGNORE_IN_UNDO = [SELECT_TAG, EDIT_SELECTED_TAG]
